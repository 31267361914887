/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import MainNav from "./MainNav"
import "./fontawesome/css/all.min.css"

const GlobalStyle = createGlobalStyle`
  :root {
    --body-background-color: #f2f2f2;
    --text-color: #4f4f4f;
    --banner-background-color: white;
    @media (prefers-color-scheme: dark) {
      --body-background-color: black;
      --text-color: #b3b3b3;
      --banner-background-color: #1a1a1a;
    }
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html, body {
    overflow: auto;
  }
  body {
    margin: 0;
    padding: 0 0 50px;
    background-color: var(--body-background-color);
    color: var(--text-color);
    font-family: 'Questrial', sans-serif;
  }
  h1,h2,h3,h4,h5,h6 {
    // font-family: 'Gothic A1', sans-serif;
    font-family: 'Fira Code', sans-serif;
    font-weight: normal;
  }
  p {
    letter-spacing: 0.03em;
    line-height: 1.4em;
  }
  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer
    transition: opacity 0.3s;
  }
`

const theme = {
  breakpoints: {
    large: 768,
  },
}

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Gothic+A1:wght@500&family=Fira+Code&family=Questrial&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <MainNav />
      <main>{children}</main>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
