import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1em 1.45em;
`

export const TextContainer = styled.div`
  margin: 0 auto;
  width: 70ch;
  max-width: calc(100vw - 20px);
`
