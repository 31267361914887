import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const Header = styled.header`
  padding: 15px;
  background: var(--banner-background-color);

  @media (min-width: ${props => props.theme.breakpoints.large + 1}px) {
    background-attachment: fixed;
  }
`

const SiteTitle = styled(Link)`
  font-family: "Fira Code", monospace;
  text-decoration: none;
`

const Nav = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--banner-background-color);
`

const NavLinksList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-evenly;
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
`

const links = [
  {
    text: "Home",
    path: "/",
    iconClass: "fas fa-home",
  },
  {
    text: "Blog",
    path: "/blog",
    iconClass: "fas fa-newspaper",
  },
  {
    text: "Shop",
    path: "/shop",
    iconClass: "fas fa-shopping-bag",
  },
]

const MainNav = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Header>
      <SiteTitle to="/">{data.site.siteMetadata.title}</SiteTitle>
      <Nav>
        <NavLinksList>
          {links.map(({ path, iconClass, text }) => (
            <li>
              <NavLink to={path}>
                <i className={iconClass} />
                <small>{text}</small>
              </NavLink>
            </li>
          ))}
        </NavLinksList>
      </Nav>
    </Header>
  )
}

export default MainNav
